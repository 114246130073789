.descargar-btn{
    border: 0px !important;
    background-color: #FF6600 !important;
    margin: 0 1rem;
}

.descargar-btn:hover{
    border: 0px !important;
    background-color: #ad2931 !important;
}

.descargar-btn:active{
    border: 0px !important;
    background-color: #ad2931 !important;
}

.certificates-amount{
    background-color: #f2f1f1;
    text-align: center;
    padding: 0.5rem 0rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size:1rem;
    font-weight: 600;    
}

.review-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 550px;
    height: 375px;
    margin-top: 1%;
    object-fit: contain !important;
}

.data-event-name{
        font-size: 1rem !important;
        font-weight: 400 !important;
        margin: 0.75rem 0rem !important;
}

.data-event-desc{
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-left: 0.5rem !important;
}